import * as React from 'react';
import Meta from 'components/whispr/Meta';
import BlackHeader from 'components/whispr/BlackHeader';
import DescFooter from 'components/DescFooter';

import 'styles/index.scss';

const TermsPage = () => {
  return (
    <>
      <Meta text={'Terms of Service'} />
      <main className={'desc'}>
        <BlackHeader text={'Terms of Service'} />
        <div className={'desc-content'}>
          <p>
            <strong>Welcome to Whispr!</strong>
          </p>

          <p>
            Thank you for signing up to use our products, including the Whispr
            application(the “Whispr Platform”), website (the “Site”), and
            services, features, content, applications, and widgets offered on
            the Whispr Platform and Site (together, we’ll call these the
            “Services”). The Services are provided by Swipr Inc. (“Swipr,” “we,”
            “us,” or “our”). The term “you” or “User” refers to the person or
            entity accessing or otherwise using our Services.
          </p>

          <p>
            These Terms of Service (or “Terms” for short) and our Privacy Policy
            (collectively, the “Agreement”) describe the rules of Swipr’s
            relationship with you and apply to your use of the Services. They
            are a legal agreement, so please read them carefully.
          </p>
          <p>
            By registering for and/or using the Services in any manner,
            including but not limited to by providing your Snapchat Bitmoji and
            display name, phone number, name, and user name, you agree to all of
            the terms and conditions contained in these Terms, which also
            incorporate Whispr’s Privacy Policy and all other operating rules,
            policies, and procedures that may be published from time to time on
            the Whispr Platform by Swipr, each of which may be updated by Swipr
            from time to time without notice to you in accordance with the terms
            set out under the “Updates to These Terms” section below. In
            addition, some services offered through the Services may be subject
            to additional terms and conditions specified by Swipr from time to
            time; your use of such services is subject to those additional terms
            and conditions, which are incorporated into these Terms by
            reference. These Terms apply to all users of the Services,
            including, without limitation, users who are contributors of
            content, information, and other materials or services on the Site,
            individual users of the Services, venues that access the Services,
            and users that have a page on the Services. IF YOU DO NOT AGREE WITH
            THESE TERMS, PLEASE DO NOT USE THESE SERVICES.
          </p>
          <p>
            The Whispr Platform is a mobile application that enables people to
            meet new friends. It is not a dating app and is not intended to be
            used as a matchmaking service.
          </p>
          <p>
            <strong>I. Acceptance of the Present Terms & Conditions</strong>
            <br />
            Whispr is a platform that allows its users (“Users”) to post
            stickers and receive responses from friends via participating social
            media platforms.
            <br />
            <br />
            By registering or using Whispr on the mobile application or website
            (collectively, the “Service”), you acknowledge that the use of the
            Service is subject to Whispr’s Terms of Service (“TOS”) which you
            must accept in order to use our Service. Furthermore, the use of our
            Service means that you also agree to our Privacy Policy and our
            Guidelines.
          </p>
          <p>
            <strong>II. Your Account</strong>
            <br />
            <strong>Account.</strong> You must register for an account
            (“Account”) on the Service. When registering for an Account, you
            agree that all information you provide to us will be accurate,
            truthful, current, and complete. Only one Account is permitted per
            User.
            <br />
            To create an Account, you must meet the following requirements:
            <br />
            <ul>
              <li>
                You are at least 17 years of age, and, if you are under 18 years
                of age (or the legal age of majority in your jurisdiction), you
                have the prior consent of your parent or legal guardian to use
                the Service
              </li>
              <li>You do not have more than one Account on the Service; and</li>
              <li>
                You have not previously been removed from the Service by us
                unless you have our express written permission to create a new
                Account.
              </li>
            </ul>
            If at any time you cease to meet these requirements, you must
            immediately delete your Account, and we retain the right to remove
            your access to our Service without warning.
            <br />
            <br />
            <strong>Account Restrictions and Passwords.</strong> To access the
            Service, you will be asked to provide us with certain registration
            information. You agree to provide correct, current, and complete
            information. You are solely responsible for maintaining the
            confidentiality of any password you create on the Service. You
            should never share your password with third parties. If you believe
            that someone has gained access to your Account, you should notify us
            immediately at whispr-support@swipr.cc.
            <br />
            <br />
            Unless expressly permitted in writing by Whispr, you may not sell,
            rent, lease, share or provide access to your Account to anyone else.
            Whispr reserves all available legal rights and remedies to prevent
            unauthorized use of and access to the Service.
            <br />
            <br />
            <strong>Account Termination.</strong> We reserve the right to
            terminate your Account for any reason, at any time, at our sole
            discretion. For example, we may suspend or terminate your Account if
            we suspect you do not meet the eligibility requirements, become
            aware of any suspicious activity, or we believe the use of your
            Account violates these Terms or any applicable law. We may require
            you to verify your eligibility at any time. You acknowledge and
            agree that you will provide such information upon our request in
            order to continue to use the Service.
            <br />
            <br />
            If your Account is terminated by you or Whispr for any reason, these
            Terms continue and remain enforceable between you and Whispr. Your
            information will be maintained and deleted in accordance with our
            Privacy Policy.
            <br />
            <br />
            <strong>Closing Your Account.</strong> You may deactivate your
            Account at any time. To do so, please contact us at
            whispr-support@swipr.cc stating your username and a request to
            deactivate your Account.
            <br />
            <br />
            We reserve the right to modify or terminate the Service, your
            Account, or your access to the Service for any reason, without
            notice, at any time, and without liability to you. If we terminate
            your access to the Service or you deactivate your Account, your data
            (including your User Content) will no longer be accessible through
            your Account, but those materials and information may persist and
            appear within the Service. We also may terminate or suspend your
            participation in the Service, at our sole discretion.
            <br />
            <br />
            <strong>
              If we decide to suspend, terminate or otherwise close your
              Account, you may not re-activate your Account or register for
              another Account unless we provide you with our prior written
              consent.{' '}
            </strong>
          </p>
          <p>
            <strong> III. Privacy</strong>
            <br />
            Your privacy matters to us. You can learn how we handle your
            information when you use our Service by reading our Privacy Policy.
            We encourage you to give the Privacy Policy a careful look because,
            by using our Service, you agree that Whispr can collect, use, and
            share your information consistent with that policy. Please note that
            Whispr may ask for permission to collect and store on our servers
            your personal information such as Contacts on your phone. We cannot
            and do not collect information unless you give us explicit
            permission to do so. While we collect information (such as your
            Contacts) to help you take full advantage of our product features,
            we understand if you feel uncomfortable giving us permission. If so,
            you are encouraged to deny giving us permission. You also have the
            ability to delete any information you have given us from our
            servers; for example, if you have given us permission to your
            Contacts, but no longer wish to have them stored in our servers, let
            us know at whispr-support@swipr.cc. You can also delete your account
            or any other information from our servers by reaching us at that
            email. To understand more on how Whispr collects, uses, shares, and
            stores your information, please read our Privacy Policy.
          </p>

          <p>
            <strong>IV. Content</strong>
            <br />
            For purposes of this Agreement, the term "Content" includes, without
            limitation, any information, data, text, photographs and other
            images, videos, audio clips, written posts, articles, comments,
            software, scripts, graphics, and interactive features generated,
            provided, or otherwise made accessible on or through the Services.
            For the purposes of this Agreement, "Content" also includes all User
            Content (as defined below).
            <br />
            <br />
            <strong>User Content.</strong> All Content added, created, uploaded,
            submitted, distributed, or posted to the Services by users
            (collectively "User Content"), whether publicly posted or privately
            transmitted, is the sole responsibility of the person who originated
            such User Content. You represent to us that all User Content
            provided by you is accurate, complete, up-to-date, and in compliance
            with all applicable laws, rules and regulations. You acknowledge
            that all Content, including User Content, accessed by you using the
            Services is at your own risk and you will be solely responsible for
            any damage or loss to you or any other party resulting from your
            actions. We do not guarantee that any Content you access on or
            through the Services is or will continue to be accurate or
            available.
            <br />
            <br />
            You are solely responsible for all User Content that you post.
            Whispr is not responsible for User Content nor does it endorse any
            opinion contained in any User Content.
            <strong>License.</strong> You grant whispr a non-exclusive,
            transferable, sub-licensable, royalty-free, perpetual, irrevocable,
            fully-paid, worldwide license to use, reproduce, make available to
            the public (e.g. perform or display), publish, translate, modify,
            create derivative works from, and distribute any of your User
            Content in connection with the Service through any medium, whether
            alone or in combination with other content or materials, in any
            manner and by any means, method or technology, whether now known or
            hereafter created. Aside from the rights specifically granted
            herein, you retain ownership of all rights, including intellectual
            property rights, in the User Content. Where applicable and permitted
            under applicable law, you also agree to waive and not enforce any
            "moral rights" or equivalent rights, such as your right to be
            identified as the author of any User Content, including Feedback,
            and your right to object to derogatory treatment of such User
            Content.
            <br />
            <br />
            <strong>Availability of Content.</strong> We do not guarantee that
            any Content will be made available on the Site or through the
            Services. We reserve the right to, but do not have any obligation
            to, (i) monitor, remove, edit, modify, or otherwise manipulate any
            Content in our sole discretion, at any time, without notice to you
            and for any reason (including, but not limited to, upon receipt of
            claims or allegations from third parties or authorities relating to
            such Content or if we are concerned that you may have violated this
            User Agreement), or for no reason at all and (ii) to remove or block
            any Content from the Services.
            <br />
            <br />
            Whispr may, but has no obligation to, monitor, review, or edit User
            Content. In all cases, Whispr reserves the right to remove or
            disable access to any User Content for any or no reason, including
            User Content that, in Whispr ‘s sole discretion, violates this
            Agreement. Whispr may take these actions without prior notification
            to you or any third party. Removal or disabling of access to User
            Content shall be at our sole discretion, and we do not promise to
            remove or disable access to any specific User Content.
            <br />
            <br />
            YOU AGREE THAT IF ANYONE BRINGS A CLAIM AGAINST WHISPR RELATED TO
            USER CONTENT THAT YOU POST, THEN, TO THE EXTENT PERMISSIBLE UNDER
            LOCAL LAW, YOU WILL INDEMNIFY AND HOLD WHISPR HARMLESS FROM AND
            AGAINST ALL DAMAGES, LOSSES, AND EXPENSES OF ANY KIND (INCLUDING
            REASONABLE ATTORNEY FEES AND COSTS) ARISING OUT OF SUCH CLAIM.
            <br />
            <br />
            <strong>Feedback.</strong> If you provide feedback, ideas, or
            suggestions to Whispr in connection with the Service (including any
            User Content) ("Feedback"), you acknowledge that the Feedback is not
            confidential and you authorize Whispr to use that Feedback without
            restriction and without payment to you. Feedback is considered a
            type of User Content.
          </p>

          <p>
            You are responsible for maintaining the confidentiality of your
            login credentials you use to sign up for Swipr, and you agree that
            you are solely responsible (to us and to others) for the activity
            that occurs under your account. If you think someone has gained
            access to your account, please immediately contact us.
          </p>
          <p>
            <strong>V. Intellectual Property</strong>
            <br />
            The Service is owned and operated by Whispr. By registering for an
            Account or by otherwise using the Service, you agree that all
            content, trademarks, and other proprietary materials and/or
            information on the Service, including, without limitation, Whispr’s
            logos, visual interfaces, graphics, design, compilation,
            information, software, computer code (including source code or
            object code), services, text, pictures, photos, video, graphics,
            music, information, data, sound files, other files and the selection
            and arrangement thereof and all other materials (collectively, “Our
            Content”) are protected by copyright, trademark, patent, trade
            secret, and other laws, and, as between you and Whispr (and/or
            third-party licensors), Whispr owns and retains all rights, title,
            and interest in the Our Content and the Service.
            <br />
            <br />
            All other trademarks, logos, and service marks (collectively, the
            “Service Marks”) are the exclusive property of Whispr (and/or
            third-party licensors) or other third parties. Nothing in these
            Terms grants you a license to use any of the Service Marks or any of
            the Whispr trade names, trademarks, service marks, logos, domain
            names, or other distinctive brand features.
            <br />
            <br />
            Unless otherwise expressly stated in writing by us, you are granted
            a limited, non-exclusive, non-transferable, non-sublicensable,
            non-assignable, revocable license to access and use the Service for
            your own personal use only, provided that, you acknowledge and agree
            that you do not acquire any ownership rights in or to the Service
            and/or Our Content or any of the Service Marks by accessing or
            otherwise using the Service. The license granted to you herein may
            be terminated by Whispr at any time, at its sole discretion. All
            rights not expressly granted in this Agreement are hereby expressly
            reserved by Whispr.
            <br />
            <br />
            You will agree not to remove, alter, or conceal any copyright,
            trademark, service mark, or other proprietary rights or notices
            incorporated in or accompanying Our Content. Nothing contained on
            the Service should be construed as granting, by implication,
            estoppel, or otherwise, any license or right to use any of Our
            Content displayed on the Service, and you will not reproduce,
            modify, adapt, prepare derivative works from, perform, display,
            publish, distribute, transmit, broadcast, sell, license, or
            otherwise exploit any of Our Content without the written permission
            of Whispr or such third party that may own any Service Marks
            displayed on the Service.
          </p>
          <p>
            <strong>VI. Community Guidelines</strong>
            <br />
            When you create an Account or otherwise use the Service, you
            acknowledge and agree that:
          </p>
          <ul>
            <li>You will abide by these Terms.</li>
            <li>
              You are responsible for keeping your Account password secret and
              secure.
            </li>
            <li>
              You are solely responsible for your conduct and for any activity
              that occurs through your Account.
            </li>
            <li>
              You agree that Whispr is not responsible or liable for the conduct
              of any User. Whispr reserves the right but has no obligation, to
              monitor or become involved in disputes between you and other
              Users.
            </li>
            <li>
              You are solely responsible for obtaining and maintaining all
              telephone, computer hardware, software, accessories, and other
              equipment needed for you to access and to use the Service.
            </li>
            <li>
              You will treat others with respect (including when communicating
              with any Whispr representatives or other employees).
            </li>
            <li>
              You will not impersonate any person or entity; falsely claim an
              affiliation with any person or entity; access the Accounts of
              others without permission; misrepresent the source, identity, or
              content of information transmitted via the Service; or perform any
              other similar fraudulent activity.
            </li>
            <li>
              You will not use the Service for any harmful, illegal, nefarious,
              or untoward purpose.
            </li>
            <li>
              You will not use or attempt to use any cheats, bots, automation
              software, hacks, or any third-party software designed to interfere
              with the Service.
            </li>
            <li>
              You will not solicit, collect, use, or attempt to use the Account
              login credentials of any other User.
            </li>
            <li>
              You will not defame, stalk, bully, spam, abuse, harass, threaten,
              impersonate, or intimidate anyone on the Service, and you must not
              stream, broadcast or post, or otherwise use information via the
              Service that may lead to violations of intellectual property or
              data privacy laws.
            </li>
            <li>
              You will not interfere or disrupt the Service or its servers or
              networks connected to the Service including by transmitting any
              worms, viruses, spyware, malware, malicious code, or any other
              code of a destructive or disruptive nature. You may not inject
              content or code or otherwise alter or interfere with how any
              Whispr page is rendered or displayed in a browser or on a device.
            </li>
            <li>
              You will not crawl, scrape, cache, or otherwise access any content
              or information (including User Content) on the Service via
              automated means.
            </li>
            <li>
              You will not create an Account through any unauthorized means,
              including but not limited to, by using an automated device,
              script, bot, spider, crawler, or scraper. Should you use any
              automatic, macro, program, or similar method, or otherwise commit
              fraud with regard to the Service, Whispr reserves the right to
              pursue legal action against you.
            </li>
            <li>
              You will not attempt to indicate in any manner, without our prior
              written permission, that you have a relationship with Whispr or
              that we have endorsed you or any products or services for any
              purpose.
            </li>
            <li>
              You will not use the Service for any illegal purpose or in
              violation of any local, state, national, or international law or
              regulation, including without limitation laws governing
              intellectual property and other proprietary rights, data
              protection, and privacy.
            </li>
            <li>
              You will not post or share any Prohibited Content, as described
              below.{' '}
            </li>
          </ul>
          <p>
            We reserve the right to refuse access to the Service to you, for any
            reason at any time, at our sole discretion.
          </p>
          <p>
            You are prohibited from uploading or sharing any content (including
            User Content) that (“<strong>Prohibited Content</strong>”):
          </p>
          <ul>
            <li>
              We may deem to be offensive, or we believe may harass, upset,
              embarrass, alarm, or annoy any other person;
            </li>
            <li>
              Is violent, threatening, obscene, or that is otherwise offensive;{' '}
            </li>
            <li>
              Is abusive, insulting, threatening, discriminatory, or that
              promotes or encourages racism, sexism, hatred, or bigotry;
            </li>
            <li>
              Encourages or facilitates any illegal activity including, without
              limitation, terrorism, inciting racial hatred, or the submission
              of which in itself constitutes committing a criminal offense;
            </li>
            <li>Is defamatory, libelous, or untrue;</li>
            <li>
              Infringes upon any third party’s rights (including, without
              limitation, intellectual property rights and privacy rights);
            </li>
            <li>
              Includes the image or likeness of another person without that
              person’s consent (or in the case of a minor, the minor’s parent or
              guardian), or the image or likeness of a minor without the
              permission of that minor’s parent or guardian;{' '}
            </li>
            <li>Is inconsistent with the intended use of the Service; or </li>
            <li>Could harm the reputation of Whispr or the Service. </li>
          </ul>
          <p>
            If you upload or share any Prohibited Content, we may immediately
            suspend or terminate your Account. You acknowledge and agree that
            posting any User Content that violates these Community Guidelines
            (or that we reasonably believe violates these Terms) may also result
            in immediate termination or suspension of your Account.
          </p>

          <p>
            <strong>VII. Purchases</strong>
            <br />
            Generally. From time to time, Whispr may offer products and services
            for purchase (“in-app purchases”) through iTunes, Google Play,
            Whispr direct billing, or other payment platforms authorized by
            Whispr. If you choose to make an in-app purchase, you will be
            prompted to confirm your purchase with the applicable payment
            provider, and your method of payment (be it your card or a third
            party account such as Google Play or iTunes) (your “Payment Method”)
            will be charged at the prices displayed to you for the service(s)
            you’ve selected as well as any sales or similar taxes that may be
            imposed on your payments, and you authorize Whispr or the
            third-party account, as applicable, to charge you.
            <br />
            <br />
            <strong>Virtual Items</strong>
            <p>
              From time to time, you may be able to purchase, a limited,
              personal, non-transferable, non-sublicensable, revocable license
              to use “virtual items,” such as gems (collectively, “Virtual
              Items”). Any Virtual Item balance shown in your account does not
              constitute a real-world balance or reflect any stored value but
              instead constitutes a measurement of the extent of your license.
              Virtual Items do not incur fees for non-use, however, the license
              granted to you in Virtual Items will terminate in accordance with
              the terms of this Agreement, when Whispr ceases providing the
              Service or your account is otherwise closed or terminated. Whispr,
              in its sole discretion, reserves the right to charge fees for the
              right to access or use Virtual Items and/ or may distribute
              Virtual Items with or without charge. Whispr may manage, regulate,
              control, modify or eliminate Virtual Items at any time. Whispr
              shall have no liability to you or any third party in the event
              that Swipr exercises any such rights. Virtual Items may only be
              redeemed through the Service. ALL PURCHASES AND REDEMPTIONS OF
              VIRTUAL ITEMS MADE THROUGH THE SERVICE ARE FINAL AND
              NON-REFUNDABLE. The provision of Virtual Items for use in the
              Service is a service that commences immediately upon the
              acceptance of your purchase of such Virtual Items. YOU ACKNOWLEDGE
              THAT WHSIPR IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON AND
              THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED
              VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS
              VOLUNTARY OR INVOLUNTARY.
            </p>
            <strong>To Request a Refund</strong>
            <p>
              If you purchased using your Apple ID, refunds are handled by
              Apple, not Whispr. To request a refund, go to iTunes, click on
              your Apple ID, select “Purchase history,” find the transaction,
              and hit “Report Problem”. You can also submit a request at
              https://getsupport.apple.com. If you purchased using your Google
              Play Store account or through Whispr directly: please contact
              customer support at whispr-support@swipr.cc with your order number
              for the Google Play Store (you can find the order number by
              logging in to Google Wallet). Please also include the first name
              and telephone number associated with your account along with your
              order number.
            </p>
            <p>
              WHISPR IS NOT RESPONSIBLE FOR THE ACTIONS, USER CONTENT,
              INFORMATION, CONTENT, OR DATA OF THIRD PARTIES, AND YOU RELEASE
              THE WHISPR PARTIES FROM ANY CLAIMS AND DAMAGES, KNOWN OR UNKNOWN,
              ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE
              AGAINST ANY SUCH THIRD PARTIES.{' '}
            </p>
          </p>
          <p>
            <strong>VIII. Copyright</strong>
            <br />
            Whispr honors copyright laws, including the Digital Millennium
            Copyright Act. We, therefore, take reasonable steps to expeditiously
            remove from our Service any infringing material that we become aware
            of.
          </p>
          <p>
            <strong>IX. Data Charges</strong>
            <br />
            By using the Service on your mobile phone (and/or any other device)
            you may be subject to charges by your Internet or mobile service
            provider, so check with them first if you are not sure, as you will
            be responsible for any such costs.
          </p>

          <p>
            <strong>X. Modifying the Service and Termination</strong>
            <br />
            Whispr is always striving to improve the Service and bring you
            additional functionality that you will find engaging and useful.
            This means we may add new product features or enhancements from time
            to time as well as remove some features, and if these actions do not
            materially affect your rights or obligations, we may not provide you
            with notice before taking them. We may even suspend the Service
            entirely, in which event we will notify you in advance unless
            extenuating circumstances, such as safety or security concerns,
            prevent us from doing so. You may terminate your account at any
            time, for any reason, by contacting us and letting us know you’d
            like to terminate your account. However, if you use a third-party
            payment account, you will need to manage in-app purchases through
            such account (e.g., iTunes, Google Play) to avoid additional
            billing. We reserve the right to terminate your user account at any
            time, including if you have failed to comply with any of the
            provisions of these Terms, or if activities occur on your account
            that, in our sole discretion, would or might cause damage to or
            impair the Services or infringe or violate any third party rights,
            or violate any applicable laws or regulations. Upon such
            termination, you will not be entitled to any refund for purchases.
          </p>
          <p>
            <strong>XI. Indemnity</strong>
            <br />
            You agree, to the extent permitted by law, to indemnify, defend, and
            hold harmless Whispr, our affiliates, directors, officers,
            stockholders, employees, licensors, and agents from and against any
            and all complaints, charges, claims, damages, losses, costs,
            liabilities, and expenses (including attorneys’ fees) due to,
            arising out of, or relating in any way to: (a) your access to or use
            of the Service; (b) your content; and (c) your breach of these Terms
          </p>

          <p>
            <strong>XII. Disclaimers</strong>
            <br />
            WHISPR PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS
            AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, GRANTS NO WARRANTIES
            OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE WITH
            RESPECT TO THE SERVICE (INCLUDING ALL CONTENT CONTAINED THEREIN),
            INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
            SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE OR NON-INFRINGEMENT. SWIPR DOES NOT REPRESENT OR WARRANT
            THAT (A) THE SERVICE WILL BE UNINTERRUPTED, SECURE, OR ERROR-FREE,
            (B) ANY DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED, OR (C)
            THAT ANY CONTENT OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICE
            WILL BE ACCURATE. WHISPR TAKES NO RESPONSIBILITY FOR ANY CONTENT
            THAT YOU OR ANOTHER USER OR THIRD PARTY POSTS, SENDS, OR RECEIVES
            THROUGH THE SERVICE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED
            THROUGH THE USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION
            AND RISK. WHISPR DISCLAIMS AND TAKES NO RESPONSIBILITY FOR ANY
            CONDUCT OF YOU OR ANY OTHER USER, ON OR OFF THE SERVICE.
          </p>

          <p>
            <strong>XIII. Limitation of Liability</strong>
            <br />
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, LIGHTSPACE INC. AND OUR
            MANAGING MEMBERS, SHAREHOLDERS, EMPLOYEES, AFFILIATES, LICENSORS,
            AGENTS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR MULTIPLE DAMAGES,
            OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR
            INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE
            LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO
            ACCESS OR USE THE SERVICE; (B) THE CONDUCT OR CONTENT OF OTHER USERS
            OR THIRD PARTIES ON OR THROUGH THE SERVICE; OR (C) UNAUTHORIZED
            ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF LIGHTSPACE INC.
            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT
            WILL LIGHTSPACE, INC.’S AGGREGATE LIABILITY FOR ALL CLAIMS RELATING
            TO THE SERVICES EXCEED THE GREATER OF $100 USD OR THE AMOUNT YOU
            PAID LIGHTSPACE INC., IF ANY, IN THE LAST 12 MONTHS.
          </p>
          <p>
            <strong>XIV. Entire Agreement; Other.</strong>
            <br />
            This Agreement, along with the Privacy Policy, the Safety Tips,
            Community Guidelines, and any terms disclosed to you if you purchase
            or have purchased additional features, products or services we offer
            on the Service, contains the entire agreement between you and Whispr
            regarding your relationship with Whispr and the use of the Service.
            If any provision of this Agreement is held invalid, the remainder of
            this Agreement shall continue in full force and effect. The failure
            of Whispr to exercise or enforce any right or provision of this
            Agreement shall not constitute a waiver of such right or provision.
            You agree that your Whispr account is non-transferable and all of
            your rights to your account and its Content terminate upon your
            death. No agency, partnership, joint venture, fiduciary or other
            special relationship or employment is created as a result of this
            Agreement and you may not make any representations on behalf of or
            bind Whispr in any manner.
          </p>
          <p>
            <strong>XV. Contact Us</strong>
            <br />
            Whispr welcomes comments, questions, concerns, or suggestions.
            Please contact us at{' '}
            <a
              style={{
                textDecoration: 'underline',
              }}
              href="mailto:whispr-support@swipr.cc"
            >
              whispr-support@swipr.cc
            </a>
          </p>
          <br />
          <br />
          <br />
        </div>
        <DescFooter />
      </main>
    </>
  );
};

export default TermsPage;
